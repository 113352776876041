* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

:root {
  --bg-light: white;
  --blue-color: #090c9b;
  --navbar-height: 8rem;
  --light-text: white;
  --text-color: black;
  --orange-color: #FF7722;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--bg-light);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--orange-color);
  cursor: grab;
  border-radius: 15px;
}

body {
  position: relative;

  &::before {
    
  overflow: hidden;
    content: "";
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("../public/image/bubble-bg.png");
    background-color: var(--bg-light);
    background-size: cover;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

/* marquee */
.marquee-container {
  font-size: 2.5rem;
  max-width: 1000px;
  overflow: hidden;
  white-space: nowrap;
  background-color: white;
  @media screen and (max-width: 683px) {
    font-size: 1.5rem;
  }
}

.marquee-text {
  display: inline-block;
  animation: marquee 50s linear infinite;
  color: #090c9b;
}

@keyframes marquee {
  0% { transform: translateX(10%); }
  100% { transform: translateX(-100%); }
}


/* ----utility start---- */
.heading-style {
  font-size: 5.5rem;
  color: var(--orange-color);
  text-shadow: 0px 1px 1px black;

  @media screen and (max-width: 700px) {
    font-size: 3.5rem;
  }
}

.para-style {
  margin: 3rem .5rem 3rem .5rem;
  padding: 0 1rem;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  color: var(--text-color);

  @media screen and (max-width: 700px) {
    font-size: 1.5rem;
  }
}

.fa-logo {
  color: var(--orange-color);
}

.text-align-left {
  text-align: left !important;
}

.blue-text {
  color: var(--blue-color);
  font-weight: 900;
}

/* ----utility end---- */

/* -----Navbar start----- */
header {
  width: 100%;
  height: var(--navbar-height);
  background-color: var(--bg-light);

  & .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.1rem 1rem;
    height: var(--navbar-height);
    cursor: pointer;

    @media screen and (max-width: 683px) {
      padding: 0 3rem;
      justify-content: left;

      @media screen and (max-width: 465px) {
        padding: 0;
      }
    }

    & img {
      width: 100%;
      height: 100%;

      @media screen and (max-width: 683px) {
        width: 6rem;
        height: 5.5rem;
      }

      &:last-child {
        height: 3rem;
        margin: 2rem 0 0 2.5rem;
        transform: scale(1.3);

        @media screen and (max-width: 683px) {
          width: 16rem;
          height: 2.5rem;

          @media screen and (max-width: 345px) {
            width: 12rem;
            margin: 0;
          }
        }
      }
    }
  }

  & .navbar {
    background: var(--bg-light);
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    max-width: 1400px;
    margin: 0 auto;
    height: 100%;
    padding: 0 1.5rem;

    & ul {
      font-size: 2rem;
      display: flex;
      gap: 2rem;
      padding-right: 2rem;
      transition: .2s ease-in-out;

      @media screen and (max-width: 1275px) {
        position: absolute;
        flex-direction: column;
        z-index: 10;
        /* top: -343px; */
        right: 0px;
        text-align: center;
        padding: 1rem;
      }

      & .hamburger {
        display: none;
        cursor: pointer;
        z-index: 10;

        @media screen and (max-width: 1275px) {
          display: block;
          font-size: 3.5rem;
          color: var(--orange-color);
          padding: 1rem;
          margin-top: .5rem;

          @media screen and (max-width: 683px) {
            margin: .5rem 0 0 0;

            @media screen and (max-width: 365px) {
              margin-top: .5rem;
              padding: 1rem 0;
            }
          }
        }
      }

      & .nav-link {
        list-style: none;
        position: relative;

        @media screen and (max-width: 1275px) {
          display: block;
        }

        & a {
          text-decoration: none;
          display: inline-block;
          outline: 0;
          border: 0;
          cursor: pointer;
          will-change: box-shadow, transform;
          background: radial-gradient(100% 100% at 100% 0%, #89E5FF 0%, #5468FF 100%);
          box-shadow: 0px 2px 4px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px rgb(58 65 111 / 50%);
          padding: 1rem;
          border-radius: 1.5rem;
          color: #fff;
          height: 4rem;
          font-size: 1.8rem;
          text-shadow: 0 1px 0 rgb(0 0 0 / 40%);
          transition: box-shadow 0.15s ease, transform 0.15s ease;

          &:hover {
            box-shadow: 0px 4px 8px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #3c4fe0;
            transform: translateY(-2px);
          }

          &:active {
            box-shadow: inset 0px 3px 7px #3c4fe0;
            transform: translateY(2px);
          }

          @media screen and (max-width: 1275px) {
            width: 100%;
          }
        }
      }
    }
    .menu{
      font-size: 5rem;
      color: var(--orange-color);
      padding: 0 1rem;
      display: none;
      @media screen and (max-width: 1275px) {
        display: block;
        @media screen and (max-width: 465px) {
          font-size: 4rem;
        }
      }
    }
  }
}

/* -----Navbar end----- */

/* -----Footer start----- */
.footer {
  background: var(--bg-light);
  font-size: 1.7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 3rem;
  max-width: 1300px;
  margin: 0 auto;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;

  @media screen and (max-width: 1000px) {
    text-align: center;
    flex-direction: column;
    gap: 3rem;
    justify-content: space-between;
    align-items: center;
  }

  & i {
    font-size: 2.2rem;
    cursor: pointer;

    &:hover {
      color: black;
    }
  }

  & p {
    margin-bottom: 1rem;
    max-width: 35rem;

    @media screen and (max-width: 1000px) {
      font-size: 1.5rem;
    }
  }

  & ul {
    & div {
      display: flex;
      justify-content: space-around;

      & li {
        list-style: none;
        margin: 1rem;

        & a {
          text-decoration: none;
          transition: .3s;
          color: var(--text-color);

          &:hover {
            color: var(--orange-color);
          }
        }
      }
    }
  }

  & .social-media-logo i {
    margin: 1rem;
  }
}

/* -----Footer end----- */

/* -----hero start----- */
.hero {
  height: calc(100vh - var(--navbar-height));
  position: relative;
  background: var(--bg-light);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('./assets/solar_panel_hero.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  & .hero-content {
    display: flex;
    align-items: center;
    position: absolute;
    top: 15rem;
    padding: 1rem;
    min-height: 26rem;
    width: 85%;
    background: rgba(255, 255, 255, 0.527);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.18);

    @media screen and (max-width: 683px) {
      width: 95%;
      top: 12.5rem;
    }

    & h1 {
      font-size: 7rem;

      @media screen and (max-width: 1172px) {
        font-size: 5rem;

        @media screen and (max-width: 827px) {
          font-size: 4rem;

          @media screen and (max-width: 683px) {
            font-size: 2.5rem;
          }
        }
      }

      & span {
        width: 100px;
        color: var(--orange-color);
        display: inline-block;
        width: 50%;
        transform: translateY(5px);

        @media screen and (max-width: 1000px) {
          transform: translateY(3px);
        }

        & img {
          width: 100%;
          filter: drop-shadow(1px 1px 2px black);
        }
      }
    }

    & p {
      font-size: 3rem;

      @media screen and (max-width: 1172px) {
        font-size: 2.1rem;

        @media screen and (max-width: 683px) {
          font-size: 1.5rem;
        }
      }
    }

    & .hero-head {
      margin-left: 2rem;
      border-left: 5px solid var(--orange-color);
      padding: 0 2rem;
      color: var(--text-color);
      overflow: hidden;

      @media screen and (max-width: 465px) {
        margin: 0;
        text-align: center;
        padding: .5rem 0;
        border-left: none;
      }
    }

  }
}

/* -----hero end----- */

/* -----company profile start----- */
.company-profile {
  text-align: center;
  max-width: 1300px;
  margin: 5rem auto 0 auto;
  background-color: var(--bg-light);
  border-radius: 1rem;
  padding: 2rem 0;
}

/* -----company profile end----- */

/* -----about/who we are start----- */
.about {
  background-color: var(--bg-light);
  color: white;
  word-spacing: 4px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 5rem auto 6rem auto;
  max-width: 1300px;
  padding: 2rem 0 2rem 0;
  border-radius: 1rem;

  & img {
    width: 20rem;
    margin: 2rem auto 0 auto;
    display: block;

    @media screen and (max-width: 683px) {
      width: 15rem;
    }
  }

  & .about-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-align: justify;
      padding: 1rem;
      width: 30rem;
      height: 48rem;
      margin: 1rem;
      background: rgba(145, 145, 145, 0.25);
      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
      backdrop-filter: blur(5.5px);
      -webkit-backdrop-filter: blur(5.5px);
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.18);
      transition: .5s;

      & img {
        border-radius: 50%;
        width: 15rem;
        /* margin: 2rem; */
      }

      &:hover {
        background: rgba(145, 145, 145, 0.45);
        transform: scale(1.01);
      }

      & .heading {
        font-size: 3rem;
        color: var(--blue-color);
        text-align: center;
        margin-bottom: 10px;
      }

      & .text {
        font-size: 1.5rem;
        line-height: 23px;
        word-spacing: 0px;
        margin: 0;
        color: var(--text-color);
      }

    }
  }
}

/* -----about end----- */

/* -----why us start----- */
.why-us {
  text-align: center;
  background-color: var(--bg-light);
  max-width: 1300px;
  min-height: 30rem;
  margin: 2rem auto;
  padding: 0px 3rem 3.5rem 3rem;
  border-radius: 1rem;

  & P {
    margin: .5rem;
  }

  & .accordion-container {
    display: flex;
    justify-self: flex-start;
    flex-direction: column;
  }
}

/* -----why us end----- */

/* Accordion style start */
.accordion {
  display: flex;
  text-align: left;
  flex-direction: column;
  padding: 3rem 1.4rem;
  margin: 2rem 0rem;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.18);

  & .accordion-header {
    font-size: 1.6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    cursor: pointer;

    & span {
      color: var(--blue-color);
    }

    & h2 {
      color: var(--blue-color);
    }
  }

  & .accordion-content {
    font-size: 1.6rem;
    margin-left: 2.7rem;
  }
}

/* Accordion style end */

/* -----services start----- */
.services {
  text-align: center;
  display: grid;
  grid-template-columns: 3fr 1fr;
  max-width: 1300px;
  margin: 2.5rem auto;
  padding: 2rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 4fr;
  }

  & .service-info {
    /* border: 2px solid red; */
    text-align: left;
    padding: 1rem 0;

    & h2 {
      color: green;
      font-weight: 600;
      font-size: 2rem;
    }

    & p {
      font-size: 1.5rem;
    }
  }

  & .service-left-div {
    background-color: var(--bg-light);
    align-self: center;
    padding: 2rem 1rem;
    border-radius: 15px;
  }

  & .service-right-div {
    background: url("../public/image/our_service.png") no-repeat center center/contain;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}

.we-provide {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 3rem;
  max-width: 1300px;
  margin: 2.5rem auto;
  padding: 2rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 4fr;
  }

  & .we-provide-left-div {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  & .we-provide-right-div {
    background-color: var(--bg-light);
    align-self: center;
    padding: 2rem 1rem;
    font-size: 2rem;
    border-radius: 15px;

    & p {
      color: var(--blue-color);
    }

    & h1 {
      text-align: center;
      margin-bottom: 1rem;
    }

    & ul {
      background: rgba(255, 255, 255, 0.2);
      background: rgba(255, 255, 255, 0.1);
      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
      backdrop-filter: blur(4px);
      -webkit-backdrop-filter: blur(4px);
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.18);
      padding: 3rem;
      margin: 1rem 0 2rem 0;
      line-height: 3.5rem;
    }
  }

  & .we-provide-left-div {
    background: url("../public/image/we_provide.png") no-repeat center center/contain;
  }
}

/* -----services end----- */

/* -----design and install start----- */
.design {
  background-color: var(--bg-light);
  color: white;
  word-spacing: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 5rem auto 6rem auto;
  max-width: 1300px;
  padding: 2rem 0 2rem 0;
  border-radius: 1rem;

  & .design-content {
    max-width: 1100px;

    & ul {
      color: var(--text-color);
      font-size: 1.7rem;
      padding: 0 3rem;

      & li {
        padding: 10px 0;
      }

      & span {
        color: brown;
        font-weight: 800;
      }
    }
  }
}

/* -----design and install end----- */

/* -----faq start----- */
.faq {
  display: flex;
  justify-content: center;
  align-items: center;

  & .faq-inner {
    max-width: 1300px;
    margin: 3rem 1rem;
    border-radius: 15px;
    line-height: 3rem;
    padding: 1rem 1rem 3rem;
    background: var(--bg-light);

    & img {
      width: 20rem;
      margin: 2rem auto 0 auto;
      display: block;

      @media screen and (max-width: 683px) {
        width: 15rem;
      }
    }
  }


  & h1 {
    padding-top: 5rem;
    text-align: center;
  }

  & .faq-content {
    padding: 0 5rem;
    margin: 0 1rem;

    @media screen and (max-width: 700px) {
      padding: 0 1rem;
    }

    & li {
      font-size: 2rem;
      line-height: 4rem;
      margin-left: 2rem;

      @media screen and (max-width: 700px) {
        font-size: 1.6rem;
        line-height: 3rem;
      }
    }

    & p {
      color: var(--blue-color);
      font-size: 3rem;
      /* font-weight: 600; */
      margin: 2.5rem 0 1rem 0;
      line-height: 4.5rem;

      @media screen and (max-width: 700px) {
        font-size: 2.3rem;
        line-height: 3.8rem;
      }
    }
  }
}

/* -----faq end----- */

/* -----contact start----- */
.contact {
  max-width: 1300px;
  margin: 3rem auto;
  text-align: center;

  & .contact-content {
    display: grid;
    grid-template-columns: 1.5fr 2fr;
    grid-gap: .5rem;
    margin: 1rem;

    @media screen and (max-width: 700px) {
      grid-template-columns: none;
    }

    & .contact-left {
      background: rgba(198, 196, 196, 0.15);
      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.18);

      & .contact-left-content {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 2rem;
        padding: 1rem;
        width: 90%;
        margin: 0 auto;

        & form {
          height: 100%;

          & label {
            width: 100%;
            color: var(--blue-color);
            display: block;
            text-align: left;
          }

          & div {
            margin: 1rem 0;
          }

          & input,
          textarea {
            padding: .5rem;
            width: 100%;
            outline: none;
            height: 3rem;
            background: rgba(198, 196, 196, 0.15);
            box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            border-radius: 10px;
            border: 1px solid rgba(255, 255, 255, 0.18);
            color: var(--text-color);
          }

          & button {
            width: 10rem;
            font-size: 2rem;
            padding: .5rem;
            border-radius: 1rem;
            color: white;
            background: var(--blue-color);
            transition: .2s;
            cursor: pointer;
            border: none;

            &:hover {
              box-shadow: inset 0px 0px 9px 1px black;
            }
          }
        }
      }
    }

    & .contact-right {
      background: var(--bg-light);
      border-radius: 10px;

      & h2 {
        font-size: 3rem;
        color: var(--blue-color);
      }
    }
  }
}

/* -----contact end----- */

/* -----timeline styling start */
.timeline-section {
  min-height: 100vh;
  padding: 2rem 1.5rem;
  max-width: 1300px;
  margin: 0 auto;

  & h1 {
    text-align: center;
    margin-bottom: 2rem;
  }
}

.timeline-items {
  max-width: 1000px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.timeline-items::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: var(--blue-color);
  left: calc(50% - 1px);
}

.timeline-item {
  margin-bottom: 4rem;
  width: 100%;
  position: relative;
}

.timeline-item:last-child {
  margin-bottom: 0;
}

.timeline-item:nth-child(odd) {
  padding-right: calc(50% + 30px);
  text-align: right;
}

.timeline-item:nth-child(even) {
  padding-left: calc(50% + 30px);
}

.timeline-dot {
  height: 1.6rem;
  width: 1.6rem;
  background-color: var(--orange-color);
  position: absolute;
  left: calc(50% - 8px);
  border-radius: 50%;
  top: 1rem;
}

.timeline-date {
  font-size: 1.8rem;
  color: var(--orange-color);
  margin: .6rem 0 1.5rem;
}

.timeline-content {
  padding: 3rem;
  border-radius: 1rem;
  background: rgba(119, 119, 119, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.timeline-content p {
  color: var(--blue-color);
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 22px;
}

/* responsive */
@media(max-width: 767px) {
  .timeline-items::before {
    left: 7px;
  }

  .timeline-item:nth-child(odd) {
    padding-right: 0;
    text-align: left;
  }

  .timeline-item:nth-child(odd),
  .timeline-item:nth-child(even) {
    padding-left: 3.7rem;
  }

  .timeline-dot {
    left: 0;
  }
}

.error {
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  max-width: 1300px;
  height: 70vh;
  background-color: var(--bg-light);
}